import { PageProps } from "gatsby";
import React from "react";

import { Scales } from "../../components/pages/Scales";

import { dnProps } from "../../js/utils";

const ScalesPage = (props: PageProps) => (
  <Scales {...{ ...dnProps, ...props }} />
);

export default ScalesPage;
