import React from "react";
import Layout from "../parts/Layout";
import { NextPageLink } from "../parts/NextPageLink";
import { PageComponentProps } from "../../types/types";
import { AudioVisualizer } from "../parts/AudioVisualizer/AudioVisualizer";

// @ts-ignore
import ScalesCMajorWholeStepsHalfStepsDn from "../../images/svg-dn/Clairnote-scales-C-major-whole-steps-half-steps.svg";
// @ts-ignore
import ScalesCMajorWholeStepsHalfStepsSn from "../../images/svg-sn/Clairnote-scales-C-major-whole-steps-half-steps.svg";

// @ts-ignore
import ScalesCMinorWholeStepsHalfStepsDn from "../../images/svg-dn/Clairnote-scales-C-minor-whole-steps-half-steps.svg";
// @ts-ignore
import ScalesCMinorWholeStepsHalfStepsSn from "../../images/svg-sn/Clairnote-scales-C-minor-whole-steps-half-steps.svg";

// @ts-ignore
import ScalesAllMajorScalesDn from "../../images/svg-dn/Clairnote-scales-all-major-scales.svg";
// @ts-ignore
import ScalesAllMajorScalesSn from "../../images/svg-sn/Clairnote-scales-all-major-scales.svg";

// @ts-ignore
import ScalesAllNaturalMinorScalesDn from "../../images/svg-dn/Clairnote-scales-all-natural-minor-scales.svg";
// @ts-ignore
import ScalesAllNaturalMinorScalesSn from "../../images/svg-sn/Clairnote-scales-all-natural-minor-scales.svg";

// @ts-ignore
import ScalesAllMelodicMinorScalesAscendingDn from "../../images/svg-dn/Clairnote-scales-all-melodic-minor-scales-ascending.svg";
// @ts-ignore
import ScalesAllMelodicMinorScalesAscendingSn from "../../images/svg-sn/Clairnote-scales-all-melodic-minor-scales-ascending.svg";

// @ts-ignore
import ScalesAllMelodicMinorScalesDescendingDn from "../../images/svg-dn/Clairnote-scales-all-melodic-minor-scales-descending.svg";
// @ts-ignore
import ScalesAllMelodicMinorScalesDescendingSn from "../../images/svg-sn/Clairnote-scales-all-melodic-minor-scales-descending.svg";

// @ts-ignore
import ScalesAllHarmonicMinorScalesDn from "../../images/svg-dn/Clairnote-scales-all-harmonic-minor-scales.svg";
// @ts-ignore
import ScalesAllHarmonicMinorScalesSn from "../../images/svg-sn/Clairnote-scales-all-harmonic-minor-scales.svg";

// @ts-ignore
import ScalesAllMajorPentatonicScalesDn from "../../images/svg-dn/Clairnote-scales-all-major-pentatonic-scales.svg";
// @ts-ignore
import ScalesAllMajorPentatonicScalesSn from "../../images/svg-sn/Clairnote-scales-all-major-pentatonic-scales.svg";

// @ts-ignore
import ScalesAllMinorPentatonicScalesDn from "../../images/svg-dn/Clairnote-scales-all-minor-pentatonic-scales.svg";
// @ts-ignore
import ScalesAllMinorPentatonicScalesSn from "../../images/svg-sn/Clairnote-scales-all-minor-pentatonic-scales.svg";

import * as pageStyles from "../global-styles/page.module.css";
import * as utilStyles from "../global-styles/util.module.css";

export const Scales = (props: PageComponentProps) => {
  const title = "Scales";
  const description = `Illustration and discussion of scales in ${props.clairnoteName} music notation. The interval structures of scales are clearly represented - easy to see, learn, and understand.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <AudioVisualizer
          sn={props.sn}
          inputMode="scales"
          hideNoteSeriesMenu={true}
        />
        <div>
          <p>
            {props.clairnoteName} makes it easy to see the interval patterns
            that make up scales. Each type of scale has a consistent visible
            pattern. On the staff above, look for the different interval
            patterns that make up each type of scale. Notice how these interval
            patterns remain consistent when you transpose the scale to a
            different root note.
          </p>
          <p>
            For example, the C major scale begins with <em>three</em>{" "}
            {props.dn ? "solid notes" : "line notes"}, followed by <em>four</em>{" "}
            {props.dn ? "hollow notes" : "space notes"}. Each major scale has
            this same {props.dn ? '"3-solid, 4-hollow"' : '"3-line, 4-space"'}{" "}
            pattern (or vice-versa:{" "}
            {props.dn ? "3-hollow, 4-solid" : "3-space, 4-line"}
            ). It reflects the pattern of whole step and half step intervals
            that make up the major scale (W-W-H-W-W-W-H).
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                props.dn
                  ? ScalesCMajorWholeStepsHalfStepsDn
                  : ScalesCMajorWholeStepsHalfStepsSn
              }
              alt={
                "C major scale in " + props.clairnoteName + " music notation"
              }
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                props.dn
                  ? ScalesCMinorWholeStepsHalfStepsDn
                  : ScalesCMinorWholeStepsHalfStepsSn
              }
              alt={
                "C minor scale in " + props.clairnoteName + " music notation"
              }
            />
          </div>
          <p>
            Minor scales also have their own particular visual pattern. As shown
            above, each natural minor scale has a{" "}
            {props.dn
              ? '"2-solid, 3-hollow, 2-solid"'
              : '"2-line, 3-space, 2-line"'}{" "}
            pattern (or vice-versa). This reflects its particular combination of
            whole steps and half steps (W-H-W-W-H-W-W).
          </p>
          <p>
            This works because half steps are always a{" "}
            {props.dn
              ? "solid note and a hollow note"
              : "line note and a space note"}
            , while whole steps are always{" "}
            {props.dn
              ? "two solid notes or two hollow notes"
              : "two line notes or two space notes"}
            . In traditional notation there is no visual distinction between
            whole steps and half steps, which makes it difficult to see and
            understand the interval patterns of scales. The clear and consistent
            visual appearance of intervals in {props.clairnoteName} makes this
            much easier.
          </p>
          <p>
            You can easily see these visual patterns in the scales shown below.
            "W" indicates a whole step and "H" indicates a half step.
          </p>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Major Scales
          </h2>
          <div className={pageStyles.imageContainer}>
            <img
              src={props.dn ? ScalesAllMajorScalesDn : ScalesAllMajorScalesSn}
              alt={
                "All major scales in " + props.clairnoteName + " music notation"
              }
            />
          </div>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Natural Minor Scales
          </h2>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                props.dn
                  ? ScalesAllNaturalMinorScalesDn
                  : ScalesAllNaturalMinorScalesSn
              }
              alt={
                "All natural minor scales in " +
                props.clairnoteName +
                " music notation"
              }
            />
          </div>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            More Scales
          </h2>
          <p>
            So far we have only considered the major and <em>natural</em> minor
            scales that contain the notes found in major and minor keys. Other
            types of scales such as{" "}
            <a
              href="http://cnx.org/content/m10856/latest/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              melodic minor, harmonic minor,
            </a>{" "}
            and{" "}
            <a
              href="https://en.wikipedia.org/wiki/Pentatonic_scale"
              target="_blank"
              rel="external noopener noreferrer"
            >
              pentatonic
            </a>{" "}
            each have their own distinct interval pattern that corresponds to an
            easily visible pattern of{" "}
            {props.dn ? "hollow and solid" : "line and space"} notes in{" "}
            {props.clairnoteName}, as you can see in the images below.
          </p>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Melodic Minor Ascending
          </h2>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                props.dn
                  ? ScalesAllMelodicMinorScalesAscendingDn
                  : ScalesAllMelodicMinorScalesAscendingSn
              }
              alt={
                "All melodic minor ascending scales in " +
                props.clairnoteName +
                " music notation"
              }
            />
          </div>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Melodic Minor Descending
          </h2>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                props.dn
                  ? ScalesAllMelodicMinorScalesDescendingDn
                  : ScalesAllMelodicMinorScalesDescendingSn
              }
              alt={
                "All melodic minor descending scales in " +
                props.clairnoteName +
                " music notation"
              }
            />
          </div>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Harmonic Minor
          </h2>
          <div className={pageStyles.imageContainer}>
            <figure>
              <img
                src={
                  props.dn
                    ? ScalesAllHarmonicMinorScalesDn
                    : ScalesAllHarmonicMinorScalesSn
                }
                alt={
                  "All harmonic minor scales in " +
                  props.clairnoteName +
                  " music notation"
                }
              />
              <figcaption className={pageStyles.caption}>
                "m3" indicates a minor third.
              </figcaption>
            </figure>
          </div>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Major Pentatonic
          </h2>
          <div className={pageStyles.imageContainer}>
            <figure>
              <img
                src={
                  props.dn
                    ? ScalesAllMajorPentatonicScalesDn
                    : ScalesAllMajorPentatonicScalesSn
                }
                alt={
                  "All major pentatonic scales in " +
                  props.clairnoteName +
                  " music notation"
                }
              />
              <figcaption className={pageStyles.caption}>
                "m3" indicates a minor third.
              </figcaption>
            </figure>
          </div>
          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>
            Minor Pentatonic
          </h2>
          <div className={pageStyles.imageContainer}>
            <figure>
              <img
                src={
                  props.dn
                    ? ScalesAllMinorPentatonicScalesDn
                    : ScalesAllMinorPentatonicScalesSn
                }
                alt={
                  "All minor pentatonic scales in " +
                  props.clairnoteName +
                  " music notation"
                }
              />
              <figcaption className={pageStyles.caption}>
                "m3" indicates a minor third.
              </figcaption>
            </figure>
          </div>

          <h2 className={`title is-5 ${utilStyles.textAlignCenter}`}>Modes</h2>
          <p>
            As with the scales shown above, {props.clairnoteName} makes it easy
            to see and understand the interval patterns found in{" "}
            <a
              href="https://en.wikipedia.org/wiki/Mode_(music)"
              target="_blank"
            >
              modes
            </a>{" "}
            (e.g. Ionian, Dorian, Phrygian, Lydian, Mixolydian, Aeolian, and
            Locrian).
          </p>
          <AudioVisualizer
            sn={props.sn}
            inputMode="modes"
            hideNoteSeriesMenu={true}
          />

          <p>
            As you can see {props.clairnoteName} represents intervals like whole
            steps, half steps, and minor thirds clearly and consistently. That
            makes it easy to see and understand the basic interval patterns that
            make up scales and modes — common patterns that are found in a broad
            range of music. Next explore how {props.clairnoteName} clearly
            represents other types of intervals.
          </p>

          <NextPageLink title="Intervals" to={props.urlDir + "intervals/"} />
        </div>
      </article>
    </Layout>
  );
};
